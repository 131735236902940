<template>
    <div class="footer">
        <b-navbar toggleable="lg" type="light" variant="light" class="header-front">
            <div class="container d-block d-sm-flex" style="padding-top: 0px;padding-bottom: 0px;">
                <router-link to="/" class="logo-footer">
                    <img src="@/assets/images/logo.png" alt="" class="logo-front">
                </router-link>

                <!-- Right aligned nav items -->
                <div class="ml-auto mb-2">
                    <ul class="navbar-nav">
                        <li class="nav-item" @click="activate('/')">
                            <router-link to="/" class="nav-link" :class="{ active : active_header == '/' || active_header == '' }">Beranda</router-link>
                        </li>
                        <li class="nav-item" @click="activate('/about-us')">
                            <router-link to="/about-us" class="nav-link" :class="{ active : active_header == '/about-us' }">Tentang Kami</router-link>
                        </li>
                        <li class="nav-item" @click="activate('/tutorial')">
                            <router-link to="/tutorial" class="nav-link" :class="{ active : active_header == '/tutorial' }">Tutorial</router-link>
                        </li>
                        <li class="nav-item" @click="activate('/contact-us')">
                            <router-link to="/contact-us" class="nav-link" :class="{ active : active_header == '/contact-us' }">Kontak Kami</router-link>
                        </li>
                    </ul>
                    <ul class="social-bar">
                        <li><a href="https://www.instagram.com/ubayasm/" target="_blank"><Icon icon="akar-icons:instagram-fill" /></a></li>
                        <li><a href="https://web.facebook.com/ubaya.school.management/" target="_blank"><Icon icon="brandico:facebook-rect" /></a></li>
                        <li><a href="https://fbeubaya.id" target="_blank"><Icon icon="mdi:web" /></a></li>
                    </ul>
                </div>
            </div>
        </b-navbar>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'

    export default {
        name: 'FooterFrontend',
        components: {
            Icon
        },
        data () {
            return {
                token: localStorage.getItem('token'),
                active_header: ''
            }
        },
        created () {
            if (this.$route.matched[0]) {
                this.get_active_header()
            }
        },
        watch: {
            $route: 'get_active_header'
        },
        methods: {
            activate: function (el) {
                this.active_header = el
            },
            get_active_header () {
                this.active_header = this.$route.matched[0].path
            }
        }
    }
</script>
