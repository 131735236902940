import Vue from 'vue'
import Vuex from 'vuex'

// Modules

import topics from './modules/topics'
import auth from './modules/auth'
import groups from './modules/groups'
import discussions from './modules/discussions'
import comments from './modules/comments'
import timelines from './modules/timelines'
import members from './modules/members'
import accountItem from './modules/account_item'
import groupCategories from './modules/group-categories'
import reports from './modules/reports'
import categories from './modules/categories'
import search from './modules/search'
import carousel from './modules/cms/carousel'
import mentors from './modules/mentor'
import skripsi from './modules/skripsi'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        topics,
        groups,
        groupCategories,
        discussions,
        comments,
        timelines,
        members,
        accountItem,
        reports,
        categories,
        search,
        carousel,
        mentors,
        skripsi
    }
})
