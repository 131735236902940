<template>
    <div id="app">
        <component :is="layout" />
    </div>
</template>

<script>
    import LayoutApp from '@/views/layouts/layout-app.vue'
    import LayoutWeb from '@/views/layouts/layout-web.vue'

    export default {
        components: {
            LayoutApp,
            LayoutWeb
        },
        computed: {
            layout () {
                if (this.$route.matched.some(record => record.meta.layout === 'app')) return 'layout-app'
                return 'layout-web'
            }
        },
        created () {
            this.$store.dispatch('auth/getUser')
                .catch(() => {
                    localStorage.removeItem('token')
                    if (this.layout === 'layout-app') this.$route.push('/')
                })
        }
    }
</script>
