import axios from 'axios'

export default {
    namespaced: true,
    state: {
        result: {
            groups: [],
            timelines: [],
            discussions: [],
            news: [],
            events: [],
            resources: []
        },
        loading: false
    },
    mutations: {
        SET_RESULT (state, val) {
            state.result = val
        },
        SET_LOADING (state, val) {
            state.loading = val
        }
    },
    actions: {
        search ({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios.get('search', params)
                    .then((response) => {
                        // console.log(response.data.result)
                        if (response.status === 200) {
                            commit('SET_RESULT', response.data.result)
                        } else {
                            reject(response)
                        }
                    })
                    .catch(reject)
            })
        }
    }
}
