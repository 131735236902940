import Vue from 'vue'
import NProgress from 'vue-nprogress'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
Vue.use(NProgress)

const nprogress = new NProgress()
nprogress.configure({ showSpinner: false })

const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: () => import('@/views/landingpage/index.vue')
    },
    {
        path: '/tutorial',
        name: 'Tutorial',
        component: () => import('@/views/landingpage/tutorial.vue')
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: () => import('@/views/landingpage/contact.vue')
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: () => import('@/views/landingpage/about.vue')
    },
    {
        path: '/terms-condition',
        name: 'TermsCondition',
        component: () => import('@/views/landingpage/terms_condition.vue')
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/landingpage/privacy_policy.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/landingpage/register.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/landingpage/login.vue')
    },
    {
        path: '/forgot',
        name: 'Forgot',
        component: () => import('@/views/landingpage/forgot.vue')
    },
    {
        path: '/verify',
        name: 'Verify',
        component: () => import('@/views/landingpage/verify.vue')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/landingpage/reset_password.vue')
    },
    {
        path: '/cms',
        name: 'CMS',
        component: () => import('@/views/cms/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'app'
        },
        children: [
            {
                path: 'carousel',
                name: 'CMSCarousel',
                component: () => import('@/views/cms/carousel.vue')
            }
        ]
    },
    {
        path: '/app',
        name: 'MainApp',
        component: () => import('@/views/application/main_app.vue'),
        meta: {
            requiresAuth: true,
            layout: 'app'
        },
        children: [
            {
                path: 'feed',
                name: 'Feed',
                component: () => import('@/views/application/feed/index.vue')
            },
            {
                path: 'search/:q',
                name: 'Search',
                component: () => import('@/views/application/search/index.vue')
            },
            {
                path: 'group',
                name: 'Group',
                component: () => import('@/views/application/group/index.vue'),
                children: [
                    {
                        path: 'reports',
                        name: 'ReportGroup',
                        component: () => import('@/views/application/group/reports/index.vue')
                    },
                    {
                        path: 'categories',
                        name: 'CategoryGroup',
                        component: () => import('@/views/application/group/categories/index.vue')
                    },
                    {
                        path: 'categories/crete',
                        name: 'CategoryGroupCreate',
                        component: () => import('@/views/application/group/categories/create.vue')
                    },
                    {
                        path: 'categories/edit/:id',
                        name: 'CategoryGroupEdit',
                        component: () => import('@/views/application/group/categories/edit.vue')
                    },
                    {
                        path: 'create',
                        name: 'CreateGroup',
                        component: () => import('@/views/application/group/create.vue')
                    },
                    {
                        path: 'discussion/:slug',
                        name: 'GroupDetailDiscussion',
                        component: () => import('@/views/application/group/detail/detail_discussion.vue')
                    },
                    {
                        path: ':code',
                        name: 'GroupDetail',
                        redirect: '/app/group/:code/timeline',
                        component: () => import('@/views/application/group/detail/index.vue'),
                        children: [
                            {
                                path: 'timeline',
                                name: 'GroupTimeline',
                                component: () => import('@/views/application/group/detail/timeline.vue')
                            },
                            {
                                path: 'timeline/:slug',
                                name: 'GroupDetailTimeline',
                                component: () => import('@/views/application/group/detail/detail_timeline.vue')
                            },
                            {
                                path: 'discussion',
                                name: 'GroupDiscussion',
                                component: () => import('@/views/application/group/detail/discussion.vue')
                            },
                            {
                                path: 'about',
                                name: 'GroupAbout',
                                component: () => import('@/views/application/group/detail/about.vue')
                            },
                            {
                                path: 'file',
                                name: 'GroupFile',
                                component: () => import('@/views/application/group/detail/file.vue')
                            },
                            {
                                path: 'member',
                                name: 'GroupMember',
                                component: () => import('@/views/application/group/detail/member.vue')
                            },
                            {
                                path: 'setting',
                                name: 'GroupSetting',
                                component: () => import('@/views/application/group/detail/setting.vue')
                            },
                            {
                                path: 'search',
                                name: 'GroupSearch',
                                component: () => import('@/views/application/group/detail/search.vue')
                            },
                            {
                                path: 'resource',
                                name: 'GroupResource',
                                component: () => import('@/views/application/group/detail/resource.vue')
                            }
                        ]
                    }
                ]
            },
            {
                path: 'news',
                name: 'News',
                component: () => import('@/views/application/news/index.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'CreateNews',
                        component: () => import('@/views/application/news/create.vue')
                    },
                    {
                        path: 'update/:code',
                        name: 'UpdateNews',
                        component: () => import('@/views/application/news/update.vue')
                    },
                    {
                        path: 'ex/:code',
                        name: 'DetailNewsExternal',
                        component: () => import('@/views/application/news/detail_ex.vue')
                    },
                    {
                        path: ':code',
                        name: 'DetailNews',
                        component: () => import('@/views/application/news/detail.vue')
                    }
                ]
            },
            {
                path: 'events',
                name: 'Events',
                component: () => import('@/views/application/event/index.vue'),
                children: [
                    {
                        path: ':code',
                        name: 'DetailEvent',
                        component: () => import('@/views/application/event/detail.vue')
                    }
                ]
            },
            {
                path: 'resources',
                name: 'Resources',
                component: () => import('@/views/application/resource/index.vue'),
                children: [
                    {
                        path: 'waiting-approval',
                        name: 'ApprovalResource',
                        component: () => import('@/views/application/resource/waiting_approval.vue')
                    },
                    {
                        path: 'create',
                        name: 'CreateResource',
                        component: () => import('@/views/application/resource/create.vue')
                    },
                    {
                        path: 'update/:code',
                        name: 'UpdateResource',
                        component: () => import('@/views/application/resource/update.vue')
                    },
                    {
                        path: ':code',
                        name: 'DetailResource',
                        component: () => import('@/views/application/resource/detail.vue')
                    }
                ]
            },
            {
                path: 'job',
                name: 'Job',
                component: () => import('@/views/application/job/index.vue'),
                children: [
                    {
                        path: ':code',
                        name: 'DetailJob',
                        component: () => import('@/views/application/job/detail.vue')
                    }
                ]
            },
            {
                path: 'setting',
                name: 'Setting',
                component: () => import('@/views/application/setting/index.vue')
            },
            {
                path: 'all_user',
                name: 'AllUser',
                component: () => import('@/views/application/user/index.vue'),
                children: [
                    {
                        path: 'update/:code',
                        name: 'UpdateUser',
                        component: () => import('@/views/application/user/update.vue')
                    }
                ]
            },
            {
                path: 'skripsi',
                name: 'Skripsi',
                component: () => import('@/views/application/skripsi/index.vue'),
                redirect: '/app/skripsi/informasi-skripsi',
                children: [
                    {
                        path: 'informasi-skripsi',
                        name: 'InformasiSkripsi',
                        component: () => import('@/views/application/skripsi/informasi-skripsi.vue')
                    },
                    {
                        path: 'pengajuan-skripsi',
                        name: 'PengajuanSkripsi',
                        component: () => import('@/views/application/skripsi/pengajuan-skripsi.vue')
                    },
                    {
                        path: 'pengajuan-bimbingan',
                        name: 'PengajuanBimbingan',
                        component: () => import('@/views/application/skripsi/pengajuan-bimbingan.vue')
                    },
                    {
                        path: 'history',
                        name: 'HistorySkripsi',
                        component: () => import('@/views/application/skripsi/history.vue')
                    },
                    {
                        path: 'pengajuan-sidang',
                        name: 'PengajuanSidangSkripsi',
                        component: () => import('@/views/application/skripsi/pengajuan-sidang.vue')
                    }
               ]
            },
            {
                path: 'skripsi/daftar-bimbingan',
                name: 'DaftarBimbingan',
                component: () => import('@/views/application/skripsi/daftar-bimbingan.vue')
            },
            {
                path: 'skripsi/jadwal-bimbingan/:id',
                name: 'DetailJadwalBimbingan',
                component: () => import('@/views/application/skripsi/detail-jadwal-bimbingan.vue')
            },
            {
                path: 'skripsi/jadwal-bimbingan/:id/ajukan-ulang',
                name: 'AjukanUlangDaftarBimbingan',
                component: () => import('@/views/application/skripsi/ajukan-ulang-daftar-bimbingan.vue')
            },
            {
                path: 'bimbingan',
                name: 'Bimbingan',
                component: () => import('@/views/application/bimbingan/index.vue'),
                redirect: '/app/bimbingan/mahasiswa-bimbingan',
                children: [
                    {
                        path: 'mahasiswa-bimbingan',
                        name: 'MahasiswaBimbingan',
                        component: () => import('@/views/application/bimbingan/mahasiswa-bimbingan.vue')
                    },
                    {
                        path: 'jadwal-bimbingan',
                        name: 'JadwalBimbingan',
                        component: () => import('@/views/application/bimbingan/jadwal-bimbingan.vue')
                    },
                    {
                        path: 'mahasiswa-selesai-bimbingan',
                        name: 'MahasiswaSelesaiBimbingan',
                        component: () => import('@/views/application/bimbingan/mahasiswa-selesai-bimbingan.vue')
                    }
               ]
            },
            {
                path: 'bimbingan/mahasiswa-bimbingan/:id',
                name: 'DetailMahasiswaBimbingan',
                component: () => import('@/views/application/bimbingan/detail-mahasiswa-bimbingan.vue')
            },
            {
                path: 'bimbingan/jadwal-bimbingan/:id',
                name: 'DetailMahasiswaJadwalBimbingan',
                component: () => import('@/views/application/bimbingan/detail-jadwal-bimbingan.vue')
            },
            {
                path: 'pengajuan-skripsi',
                name: 'AdminPengajuanSkripsi',
                component: () => import('@/views/application/pengajuan-skripsi/index.vue')
            },
            {
                path: 'pengajuan-skripsi/:id',
                name: 'AdminDetailPengajuanSkripsi',
                component: () => import('@/views/application/pengajuan-skripsi/detail-pengajuan-skripsi.vue')
            },
            {
                path: 'mbkm',
                name: 'MBKM',
                component: () => import('@/views/application/mbkm/index.vue'),
                children: [
                    {
                        path: 'create',
                        name: 'CreateMBKM',
                        component: () => import('@/views/application/mbkm/create.vue')
                    },
                    {
                        path: 'update/:code',
                        name: 'UpdateMBKM',
                        component: () => import('@/views/application/mbkm/update.vue')
                    },
                    {
                        path: ':code',
                        name: 'DetailMBKM',
                        component: () => import('@/views/application/mbkm/detail.vue')
                    }
                ]
            },
            {
                path: 'account',
                name: 'Account',
                component: () => import('@/views/application/account/index.vue')
            },
            {
                path: 'account/friends',
                name: 'AccountFriends',
                component: () => import('@/views/application/account/friend.vue')
            },
            {
                path: 'account/friends-unconfirmed',
                name: 'AccountFriendsUnconfirmed',
                component: () => import('@/views/application/account/friend_unconfirmed.vue')
            },
            {
                path: 'profile/:code',
                name: 'Profile',
                component: () => import('@/views/application/account/profile.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = 'INFINITY'
    nprogress.start()
    nprogress.set(0.1)
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') === '' || localStorage.getItem('token') === null) {
            next({ name: 'Login' })
        } else {
            // if (localStorage.getItem('verify') === null || localStorage.getItem('verify') === '0000-00-00 00:00:00' || localStorage.getItem('verify') === 'undefined') {
            //     next({ name: 'Verify' })
            // } else {
            // }
            next()
        }
    } else {
        // || localStorage.getItem('verify') === 'null' || localStorage.getItem('verify') === '0000-00-00 00:00:00' || localStorage.getItem('verify') === 'undefined'
        if (localStorage.getItem('token') === '' || localStorage.getItem('token') === null) {
            next()
        } else {
            next({ name: 'Feed' })
        }
    }
})
router.afterEach(() => {
    setTimeout(() => nprogress.done(), 500)
})

export default router
