import axios from 'axios'
import { buildFormData, multipartHeader } from '@/axios'
const API_PATH = 'discussions'

export default {
    namespaced: true,
    state: {
        filter: {
            group_id: '',
            topic: ''
        },
        data: [],
        loading: false,
        selected: {
            loading: false,
            data: {
                author: {}
            }
        }
    },
    mutations: {
        SET_DATA (state, val) {
            state.data = val
        },
        APPEND_DATA (state, val) {
            val.forEach(i => {
                if (state.data.findIndex(item => item.id === i.id) < 0) {
                    state.data.push(i)
                }
            })
        },
        SET_LOADING (state, val) {
            state.loading = val
        },
        SET_SELECTED_DATA (state, val) {
            state.selected.data = val
        },
        SET_SELECTED_LOADING (state, val) {
            state.selected.loading = val
        }
    },
    actions: {
        fetch ({ commit }, params) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(API_PATH, { params })
                    .then((res) => res.data)
                    .then((res) => {
                        if (params.append) {
                            commit('APPEND_DATA', res.result.data)
                        } else {
                            commit('SET_DATA', [])
                            commit('SET_DATA', res.result.data)
                        }
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        create ({ commit }, data) {
            return axios.post(API_PATH, data)
        },
        update ({ commit }, { id, data }) {
            return axios.put(`${API_PATH}/${id}`, data)
        },
        show ({ commit }, slug) {
            commit('SET_SELECTED_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/${slug}`)
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_SELECTED_DATA', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_SELECTED_LOADING', false))
            })
        },
        addComment ({ commit }, { slug, content, parentId, attachments }) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/${slug}/comment`, buildFormData({ content, parent_id: parentId, attachments }), multipartHeader)
                    .then(res => res.data)
                    .then(res => res.result)
                    .then(resolve)
                    .catch(reject)
            })
        },
        enableComment ({ commit }, slug) {
            return axios.post(`${API_PATH}/${slug}/enable-comment`)
        },
        disableComment ({ commit }, slug) {
            return axios.post(`${API_PATH}/${slug}/disable-comment`)
        },
        delete ({ commit }, slug) {
            commit('SET_SELECTED_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.delete(`${API_PATH}/${slug}`)
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_SELECTED_DATA', { author: {} })
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_SELECTED_LOADING', false))
            })
        }
    }
}
