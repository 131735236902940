<template>
    <div class="header" :class="menu_collapse">
        <b-navbar toggleable="lg" type="light" variant="light" class="header-front">
            <div class="container" style="padding-top: 0px;padding-bottom: 0px;">
                <router-link to="/">
                    <img src="@/assets/images/logo.png" alt="" class="logo-front">
                </router-link>

                <!-- Right aligned nav items -->
                <div class="ml-auto d-flex">
                    <router-link to="/login" class="nav-link d-block d-lg-none"><b-button variant="primary" class="my-2 my-sm-0">Login</b-button></router-link>
                    <div class="menu-mobile ml-5 align-self-center" v-on:click="menuCollapse">
                        <Icon icon="entypo:menu" />
                    </div>
                </div>
                <ul class="navbar-nav ml-auto">
                    <li><div v-on:click="menuHide"><Icon icon="gg:close-o" class="close-menu" /></div></li>
                    <li class="nav-item" @click="activate('/')">
                        <router-link to="/" class="nav-link" :class="{ active : active_header == '/' || active_header == '' }">Beranda</router-link>
                    </li>
                    <li class="nav-item" @click="activate('/about-us')">
                        <router-link to="/about-us" class="nav-link" :class="{ active : active_header == '/about-us' }">Tentang Kami</router-link>
                    </li>
                    <li class="nav-item" @click="activate('/tutorial')">
                        <router-link to="/tutorial" class="nav-link" :class="{ active : active_header == '/tutorial' }">Tutorial</router-link>
                    </li>
                    <li class="nav-item" @click="activate('/contact-us')">
                        <router-link to="/contact-us" class="nav-link" :class="{ active : active_header == '/contact-us' }">Kontak Kami</router-link>
                    </li>
                    <li class="nav-item d-none d-lg-block" v-if="token === null">
                        <router-link to="/login" class="nav-link"><b-button variant="primary" class="my-2 my-sm-0">Login</b-button></router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link to="/register" class="nav-link"><b-button variant="primary" class="my-2 my-sm-0">Daftar</b-button></router-link>
                    </li> -->
                    <li class="nav-item d-none d-lg-block" v-if="token !== null">
                        <router-link to="/app/feed" class="nav-link"><b-button variant="primary" class="my-2 my-sm-0">Akun</b-button></router-link>
                    </li>
                </ul>
            </div>
        </b-navbar>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'
    export default {
        name: 'HeaderFrontend',
        components: {
            Icon
        },
        data () {
            return {
                token: localStorage.getItem('token'),
                active_header: '',
                menu_collapse: ''
            }
        },
        created () {
            if (this.$route.matched[0]) {
                this.get_active_header()
            }
        },
        watch: {
            $route: 'get_active_header'
        },
        methods: {
            activate: function (el) {
                this.active_header = el
            },
            get_active_header () {
                this.active_header = this.$route.matched[0].path
            },
            menuCollapse () {
                this.menu_collapse = 'menu-collapse'
            },
            menuHide () {
                this.menu_collapse = ''
            }
        }
    }
</script>
