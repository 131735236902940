<template>
    <div class="loading-container">
        <div v-if="loading" class="loading-overlay" :class="spinnerClass">
            <b-spinner small label="Loading..." />
        </div>
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            spinnerClass: {
                type: String,
                default: ''
            }
        }
    }
</script>
