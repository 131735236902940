<template>
    <div class="sidebar">
        <div class="sidebar_header">
            <img src="@/assets/images/logo.png" alt="">
            <span class="btn-mobile" @click="toogleMenu()"></span>
        </div>
        <div class="sidebar_inner" data-simplebar>
            <h3 class="text-lg mt-3 ml-2 is-title"> Menu </h3>
            <SmoothScrollbar id="app_sidebar">
            <ul>
                <li @click="activate('/app/feed')" :class="{ active : active_el == '/app/feed' || active_el == '' }">
                    <router-link to="/app/feed">
                        <Icon icon="ic:round-dashboard" />
                        <span>Feed</span>
                    </router-link>
                </li>
                <li @click="activate('/app/group')" :class="{ active : active_el == '/app/group' }">
                    <router-link to="/app/group">
                        <Icon icon="mdi:account-group" />
                        <span> Group </span>
                    </router-link>
                </li>
                <li @click="activate('/app/news')" :class="{ active : active_el == '/app/news' }">
                    <router-link to="/app/news">
                        <Icon icon="fluent:news-24-filled" />
                        <span> News</span>
                    </router-link>
                </li>
                <li @click="activate('/app/events')" :class="{ active : active_el == '/app/events' }">
                    <router-link to="/app/events">
                        <Icon icon="ion:calendar-number-sharp" />
                        <span> Events </span>
                    </router-link>
                </li>
                <li @click="activate('/app/resources')" :class="{ active : active_el == '/app/resources' }" >
                    <router-link to="/app/resources">
                        <Icon icon="ant-design:file-filled" />
                        <span> Resources</span>
                    </router-link>
                </li>
                <li @click="activate('/app/job')" :class="{ active : active_el == '/app/job' }">
                    <router-link to="/app/job">
                        <Icon icon="bx:bxs-briefcase" />
                        <span> Career</span>
                    </router-link>
                </li>
                <li v-if="user && user.type === 'admin-skripsi'" @click="activate('/app/pengajuan-skripsi')" :class="{ active : active_el == '/app/pengajuan-skripsi' }">
                    <router-link to="/app/pengajuan-skripsi">
                        <Icon icon="zondicons:book-reference" />
                        <span> Skripsi</span>
                    </router-link>
                </li>
                <li v-if="user && user.type === 'dosen'" @click="activate('/app/bimbingan')" :class="{ active : active_el == '/app/bimbingan' }">
                    <router-link to="/app/bimbingan">
                        <Icon icon="zondicons:book-reference" />
                        <span> Skripsi</span>
                    </router-link>
                </li>
                <li v-if="user && user.type !== 'dosen' && user.type !== 'admin-skripsi'" @click="activate('/app/skripsi')" :class="{ active : active_el == '/app/skripsi' }">
                    <router-link to="/app/skripsi">
                        <Icon icon="zondicons:book-reference" />
                        <span> Skripsi</span>
                    </router-link>
                </li>
                <li @click="activate('/app/mbkm')" :class="{ active : active_el == '/app/mbkm' }">
                    <router-link to="/app/mbkm">
                        <Icon icon="zondicons:badge" />
                        <span> MBKM</span>
                    </router-link>
                </li>
            </ul>
            <hr>
            <div v-if="user.is_admin === 1">
                <h3 class="text-lg mt-3 ml-2 is-title"> Admin Menu </h3>
                <ul>
                    <li @click="activate('/cms/carousel')" :class="{ active : active_el == '/cms/carousel' }">
                        <router-link to="/cms/carousel">
                            <Icon icon="bx:image" />
                            <span> Carousel</span>
                        </router-link>
                    </li>
                    <li @click="activate('/app/all_user')" :class="{ active : active_el == '/app/all_user' }">
                        <router-link to="/app/all_user">
                            <Icon icon="mdi:account-group" />
                            <span> All User</span>
                        </router-link>
                    </li>
                </ul>
                <hr>
            </div>
            <h3 class="text-lg mt-3 ml-2 is-title"> Account </h3>
            <ul>
                <li @click="activate('/app/account')" :class="{ active : active_el == '/app/account' }">
                    <router-link to="/app/account">
                        <Icon icon="bx:bxs-user" />
                        <span> Account</span>
                    </router-link>
                </li>
                <li @click="activate('/app/setting')" :class="{ active : active_el == '/app/setting' }">
                <router-link to="/app/setting">
                    <Icon icon="ant-design:setting-filled" />
                    <span>Setting</span>
                </router-link>
                </li>
            </ul>
            </SmoothScrollbar>
        </div>
    </div>
</template>
<script>
    import { Icon } from '@iconify/vue2'

    export default {
        components: {
            Icon
        },
        data: function () {
            return {
                active_el: this.$route.matched[1].path
            }
        },
        methods: {
            activate: function (el) {
                this.active_el = el
            },
            toogleMenu: function (el) {
                document.getElementById('wrapper').classList.toggle('is-collapse')
                document.getElementById('wrapper').classList.toggle('is-active')
            },
            actionLogout () {
                localStorage.removeItem('token')
                this.$router.go('/')
            }
        },
        computed: {
            user () {
                return this.$store.state.auth.user
            }
        }
    }
</script>
