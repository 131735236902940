import axios from 'axios'
import { buildFormData, multipartHeader } from '@/axios'
const API_PATH = 'user'

export const NOTIFICATION_TYPE_GROUP_JOIN_REQUEST = 1
export const NOTIFICATION_TYPE_GROUP_JOIN_REQUEST_APPROVED = 2
export const NOTIFICATION_TYPE_GROUP_JOIN_REQUEST_REJECTED = 3
export const NOTIFICATION_TYPE_GROUP_MEMBER_JOINED = 4
export const NOTIFICATION_TYPE_GROUP_MEMBER_LEAVE = 5
export const NOTIFICATION_TYPE_GROUP_MEMBER_ADDED_AS_ADMIN = 6
export const NOTIFICATION_TYPE_GROUP_MEMBER_REMOVED_FROM_ADMIN = 7
export const NOTIFICATION_TYPE_GROUP_MEMBER_INVITATION_RECEIVED = 8
export const NOTIFICATION_TYPE_GROUP_DISCUSSION_CREATED = 9
export const NOTIFICATION_TYPE_GROUP_DISCUSSION_NEW_COMMENT = 10
export const NOTIFICATION_TYPE_GROUP_TIMELINE_CREATED = 11
export const NOTIFICATION_TYPE_GROUP_TIMELINE_NEW_COMMENT = 12
export const NOTIFICATION_TYPE_NEW_LIKE_COMMENT_TIMELINE = 13
export const NOTIFICATION_TYPE_NEW_LIKE_COMMENT_DISCUSSION = 14
export const NOTIFICATION_TYPE_NEW_LIKE_TIMELINE = 15
export const NOTIFICATION_TYPE_CREATE_NEW_SKRIPSI = 16
export const NOTIFICATION_TYPE_CREATE_NEW_BIMBINGAN = 17
export const NOTIFICATION_TYPE_APPROVE_BIMBINGAN = 18
export const NOTIFICATION_TYPE_INVITE_SKRIPSI = 19
export const NOTIFICATION_TYPE_PENGAJUAN_SKRIPSI = 20
export const NOTIFICATION_TYPE_PENGAJUAN_SIDANG = 21

export default {
    namespaced: true,
    state: {
        user: null,
        notification: {
            loading: false,
            items: []
        },
        selected: {
            loading: false,
            user: {}
        }
    },
    mutations: {
        SET_USER_DATA (state, val) {
            state.user = val
        },
        SET_LOADING (state, val) {
            state.loading = val
        },
        SET_SELECTED_DATA (state, val) {
            state.selected.user = val
        },
        SET_SELECTED_LOADING (state, val) {
            state.selected.loading = val
        },
        SET_NOTIFICATION_ITEMS (state, val) {
            state.notification.items = val
        },
        SET_NOTIFICATION_LOADING (state, val) {
            state.notification.loading = val
        }
    },
    actions: {
        getUser ({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('user')
                    .then((response) => {
                        if (response.data.user) {
                            commit('SET_USER_DATA', response.data.user)
                            commit('SET_NOTIFICATION_ITEMS', response.data.user.notifications ? response.data.user.notifications : [])
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    })
                    .catch(reject)
            })
        },
        update ({ commit }, { data }) {
            return axios.post('user', buildFormData(data), multipartHeader)
        },
        show ({ commit }, username) {
            commit('SET_SELECTED_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/${username}`)
                    .then((response) => {
                        if (response.data.user) {
                            commit('SET_SELECTED_DATA', response.data.user)
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    })
                    .catch(reject)
                    .finally(() => commit('SET_SELECTED_LOADING', false))
            })
        },
        addFriend ({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/add-friend/${id}`)
                    .then(res => res.data)
                    .then(data => {
                        resolve(data.result)
                    })
                    .catch(reject)
            })
        },
        removeFriend ({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/remove-friend/${id}`)
                    .then(res => res.data)
                    .then(data => {
                        // console.log(data)
                        resolve(data.result)
                    })
                    .catch(reject)
            })
        },
        confirmFriend ({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/confirm-friend/${id}`)
                    .then(res => res.data)
                    .then(data => {
                        // console.log(data)
                        resolve(data.result)
                    })
                    .catch(reject)
            })
        },
        getFriend ({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/friends`)
                    .then(response => console.log(response))
            })
        },
        readNotification ({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/read-notification`)
                    .then(res => res.data)
                    .then(data => {
                        // console.log(data)
                        resolve(data.result)
                    })
                    .catch(reject)
            })
        }
    }
}
