<template>
    <header>
        <div class="header_wrap">
            <div class="header_inner mcontainer">
            <div class="left_side">
                <span class="slide_menu" @click="toogleMenu()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" fill="currentColor"></path>
                </svg>
                </span>

                <div id="logo">
                <a href="homepage.html">
                    <img src="@/assets/images/logo.png" alt="">
                    <img src="@/assets/images/logo.png" class="logo_mobile" alt="">
                </a>
                </div>
            </div>

            <!-- search icon for mobile -->
                <div class="header_search"><i class="uil-search-alt"></i>
                    <input value="" type="text" class="form-control" v-model="search" v-on:keyup.enter="onSearch"
                        placeholder="Search for Friends , Videos and more.." autocomplete="off">
                </div>

                <div class="right_side">
                    <div class="header_widgets">
                        <div class="header-search-icon" uk-toggle="target: #wrapper ; cls: show-searchbox"> </div>
                        <notification-items class="dropdown-notification" :data="notification.items" />

                        <a href="#" style="margin-right: 10px;" v-if="user">
                            <img :src="user.images !== '' && user.images !== null ? user.images : require('@/assets/images/avatars/avatar-1.jpg')" class="is_avatar" alt="">
                        </a>

                        <b-dropdown id="dropdown-profile" :text="user.fullname" v-if="user" class="md:block hidden">
                            <b-dropdown-item to="/app/account">Account</b-dropdown-item>
                            <b-dropdown-item href="#" @click="actionLogout()">Logout</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    import { mapState } from 'vuex'
    import NotificationItems from './NotificationItems.vue'

    export default {
        components: {
            NotificationItems
        },
        computed: mapState('auth', ['user', 'notification']),
        methods: {
            toogleMenu: function (el) {
                document.getElementById('wrapper').classList.toggle('is-collapse')
                document.getElementById('wrapper').classList.toggle('is-active')
            },
            actionLogout () {
                localStorage.removeItem('token')
                this.$router.go('/')
            },
            onSearch () {
                this.$router.push({ name: 'Search', params: { q: this.search } })
                // if (this.$route.name !== 'Search') {
                // } else {
                //     this.$eventHub.emit('GLOBAL_SEARCH', { q: this.search })
                // }
            }
        },
        data () {
            return {
                search: ''
            }
        }
    }
</script>
