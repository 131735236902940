import axios from 'axios'
const API_PATH = 'comments'

export default {
    namespaced: true,
    actions: {
        like ({ commit }, id) {
            return axios.post(`${API_PATH}/${id}/like`)
        },
        unlike ({ commit }, id) {
            return axios.post(`${API_PATH}/${id}/unlike`)
        },
        delete ({ commit }, id) {
            return axios.post(`${API_PATH}/${id}/delete`)
        }
    }
}
