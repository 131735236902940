import { serialize } from 'object-to-formdata'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
require('dayjs/locale/id')

dayjs.locale('id')
dayjs.extend(relativeTime)

const DATESTORE_FORMAT = 'YYYY-MM-DD'
const DATE_FORMAT = 'D MMM YYYY'
const THREE_MONTH_FORMAT = 'MMM YYYY'
const MONTH_FORMAT = 'MMMM YYYY'
const TIME_FORMAT = 'HH:mm'
const DATETIME_FORMAT = `${DATE_FORMAT}, ${TIME_FORMAT}`

/**
 * Format date string to readable date format
 * @param {Date String} date
 * @param {string} outputFormat
 * @returns
 */
export const formatDate = (date, outputFormat = DATE_FORMAT) => {
    const dateMoment = dayjs(date)
    if (!dateMoment.isValid()) return '--'
    return dateMoment.format(outputFormat)
}

/**
 * Format date string to time format
 * @param {Date String} date
 * @param {string} outputFormat
 * @returns
 */
 export const formatDateTime = (date, outputFormat = DATETIME_FORMAT) => {
    const dateMoment = dayjs(date)
    if (!dateMoment.isValid()) return '--'
    return dateMoment.format(outputFormat)
}

/**
 * Format date string to readable date and time format
 * @param {Date String} date
 * @param {string} outputFormat
 * @returns
 */
 export const formatTime = (date, outputFormat = TIME_FORMAT) => {
    const dateMoment = dayjs(date)
    if (!dateMoment.isValid()) return '--'
    return dateMoment.format(outputFormat)
}

/**
 * Format date string to mysql date format
 * @param {Date String} date
 * @param {string} outputFormat
 * @returns
 */
export const formatDateStore = (date, outputFormat = DATESTORE_FORMAT) => {
    const dateMoment = dayjs(date)
    if (!dateMoment.isValid()) return '--'
    return dateMoment.format(outputFormat)
}

/**
 * Format date string to readable human format
 * @param {Date String} date
 * @param {string} outputFormat
 * @returns
 */
export const formatDateFromNow = (date) => {
    const dateMoment = dayjs(date)
    if (!dateMoment.isValid()) return '--'
    return dateMoment.fromNow()
}

export const formatDateThreeMonth = (date, outputFormat = THREE_MONTH_FORMAT) => {
    const dateMoment = dayjs(date)
    if (!dateMoment.isValid()) return '--'
    if (date === '1890-01-01') return ''
    return dateMoment.format(outputFormat)
}

export const formatDateMonth = (date, outputFormat = MONTH_FORMAT) => {
    const dateMoment = dayjs(date)
    if (!dateMoment.isValid()) return '--'
    if (date === '1890-01-01') return ''
    return dateMoment.format(outputFormat)
}

/**
 * Generate FormData from object
 * @param {object} data
 * @returns FormData
 */
export const toFormData = (data) => serialize(data, {
    booleansAsIntegers: true,
    allowEmptyArrays: true,
    indices: true
})

/**
 * Limit string to specified length
 * @param {string} str
 * @param {number} limit
 */
export const limitString = (str, limit = 100) => {
    if (str.length > limit) {
        return str.substring(0, limit) + '...'
    }
    return str
}

export default {
    methods: {
        formatDate,
        formatTime,
        formatDateTime,
        formatDateFromNow,
        toFormData,
        formatDateStore,
        formatDateThreeMonth,
        formatDateMonth,
        limitString
    }
}
