<template>
    <div id="wrapper" ref="wrapper">
        <HeaderFront />
        <router-view />
        <FooterFront />
    </div>
</template>

<script>
    import HeaderFront from '@/components/HeaderFront.vue'
    import FooterFront from '@/components/FooterFront.vue'

    export default {
        components: {
            HeaderFront,
            FooterFront
        }
    }
</script>
