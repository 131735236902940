<template>
    <div>
        <a class="header__link" @click="readNotification">
            <button class="header--button is_icon notification" key="on" @click="show = !show">
                <Icon icon="clarity:notification-solid" />
                <span v-if="unRead > 0 && !read">{{ unRead }}</span>
            </button>
        </a>
          <!-- Dropdown Menu -->
        <transition name="dropdown">
            <div class="dropdown__menu" v-bind:class="{ active: show }" v-if="show">
                <smooth-scrollbar class="dropdown__menu-items">
                    <ul class="dropdown__menu-nav">
                        <notification-card v-for="item in data" :key="item.id" :data="item" />
                    </ul>
                </smooth-scrollbar>
            </div>
        </transition>
    </div>
</template>

<script>
    import { Icon } from '@iconify/vue2'
    import NotificationCard from './NotificationCard.vue'
    export default {
        components: {
            Icon,
            NotificationCard
        },
        props: {
            data: {
                type: Array,
                default: () => []
            }
        },
        data () {
            return {
                show: false,
                read: false
            }
        },
        computed: {
            unRead () {
                return this.data.filter((i) => i.read === 0).length
            }
        },
        methods: {
            readNotification () {
                this.read = true
                this.$store.dispatch('auth/readNotification')
            }
        },
        watch: {
            $route: function () {
                this.show = false
            }
        }
    }
</script>
