import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixins'
import linkify from 'vue-linkify'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueContentPlaceholders from 'vue-content-placeholders'
import VueSweetalert2 from 'vue-sweetalert2'
import vSelect from 'vue-select'
import Loading from '@/components/Loading.vue'
import 'vue-select/dist/vue-select.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import './axios'
import VueSocialSharing from 'vue-social-sharing'
import EventHub from 'vue-event-hub'
import VModal from 'vue-js-modal'
import SmoothScrollbar from 'vue-smooth-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/icons.css'
import './assets/css/uikit.css'
import './assets/css/style.css'
import './assets/css/tailwind.css'

Vue.use(EventHub)

const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo, {
    container: 'body',
    duration: 300,
    easing: 'linear',
    offset: -200,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

Vue.use(SmoothScrollbar)
Vue.use(VModal)
Vue.use(VueSocialSharing)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueContentPlaceholders)
Vue.use(VueSweetalert2)
Vue.mixin(mixins)
Vue.component('v-select', vSelect)
Vue.component('loading', Loading)
Vue.directive('linkified', linkify)

Vue.config.productionTip = false
Vue.prototype.$placeholder = require('@/assets/images/placeholder/placeholder-1.jpg')
Vue.prototype.$placeholder2 = require('@/assets/images/placeholder/placeholder-2.jpg')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
