import axios from 'axios'
import { buildFormData, multipartHeader } from '@/axios'
const API_PATH = 'cms/carousel'

export default {
    namespaced: true,
    state: {
        data: [],
        loading: false
    },
    mutations: {
        SET_DATA (state, val) {
            state.data = val
        },
        SET_LOADING (state, val) {
            state.loading = val
        }
    },
    actions: {
        fetch ({ commit }, params = {}) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(API_PATH, { params })
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_DATA', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        create ({ commit }, data) {
            return axios.post(API_PATH, buildFormData(data), multipartHeader)
        },
        update ({ commit }, { id, data }) {
            return axios.post(`${API_PATH}/${id}`, buildFormData(data), multipartHeader)
        },
        delete ({ commit }, id) {
            return axios.delete(`${API_PATH}/${id}`)
        }
    }
}
