import axios from 'axios'
import { buildFormData, multipartHeader } from '@/axios'
const API_PATH = 'category_group'

export default {
    namespaced: true,
    state: {
        loading: false,
        data: [],
        selected: {
            loading: false,
            data: {
                author: {}
            }
        }
    },
    mutations: {
        SET_DATA (state, val) {
            state.data = val
        },
        SET_LOADING (state, val) {
            state.loading = val
        },
        SET_SELECTED_DATA (state, val) {
            state.selected.data = val
        },
        SET_SELECTED_LOADING (state, val) {
            state.selected.loading = val
        }
    },
    actions: {
        get ({ commit }) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(API_PATH)
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_DATA', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => {
                        commit('SET_LOADING', false)
                    })
            })
        },
        create ({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios.post(API_PATH, buildFormData(data), multipartHeader)
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        update ({ commit, dispatch }, { id, data }) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/${id}`, buildFormData(data), multipartHeader)
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        show ({ commit }, slug) {
            commit('SET_SELECTED_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/${slug}`)
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_SELECTED_DATA', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_SELECTED_LOADING', false))
            })
        },
        delete ({ commit, dispatch }, id) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/${id}`, { _method: 'DELETE' })
                    .then((res) => res.data)
                    .then(async (res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        }
    }
}
