import axios from 'axios'
import { serialize } from 'object-to-formdata'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common.token = localStorage.getItem('token')
axios.defaults.withCredentials = false

export const buildFormData = (data) => serialize(data, {
    booleansAsIntegers: true,
    allowEmptyArrays: true,
    indices: true
})

export const multipartHeader = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}
