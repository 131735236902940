import axios from 'axios'
const API_PATH = 'group/member'

export default {
    namespaced: true,
    state: {
        data: [],
        loading: false
    },
    mutations: {
        SET_DATA (state, val) {
            state.data = val
        },
        SET_LOADING (state, val) {
            state.loading = val
        }
    },
    actions: {
        fetch ({ commit }, params) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(API_PATH + '/' + params)
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_DATA', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        }
    }
}
