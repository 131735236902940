<template>
    <li class="dropdown__menu-item notification-card">
        <router-link :to="notificaitonRoute ? notificaitonRoute : ''" class="dropdown__menu-link px-3">
            <div class="d-flex">
                <div>
                    <img v-if="data.author" :src="data.author.images !== '' && data.author.images !== null ? data.author.images : require('@/assets/images/avatars/avatar-1.jpg')" class="bg-gray-200 border border-white rounded-full w-10 h-10 mw-auto" alt="">
                </div>
                <div class="dropdown__menu-text">
                    <p v-html="data.content_html"></p>
                    <time>{{ formatDateFromNow(data.created_at) }}</time>
                </div>
            </div>
        </router-link>
    </li>
</template>

<script>
    import {
        NOTIFICATION_TYPE_GROUP_JOIN_REQUEST,
        NOTIFICATION_TYPE_GROUP_JOIN_REQUEST_APPROVED,
        NOTIFICATION_TYPE_GROUP_JOIN_REQUEST_REJECTED,
        NOTIFICATION_TYPE_GROUP_MEMBER_JOINED,
        NOTIFICATION_TYPE_GROUP_MEMBER_LEAVE,
        NOTIFICATION_TYPE_GROUP_MEMBER_ADDED_AS_ADMIN,
        NOTIFICATION_TYPE_GROUP_MEMBER_REMOVED_FROM_ADMIN,
        NOTIFICATION_TYPE_GROUP_MEMBER_INVITATION_RECEIVED,
        NOTIFICATION_TYPE_GROUP_DISCUSSION_CREATED,
        NOTIFICATION_TYPE_GROUP_DISCUSSION_NEW_COMMENT,
        NOTIFICATION_TYPE_GROUP_TIMELINE_CREATED,
        NOTIFICATION_TYPE_GROUP_TIMELINE_NEW_COMMENT,
        NOTIFICATION_TYPE_NEW_LIKE_COMMENT_TIMELINE,
        NOTIFICATION_TYPE_NEW_LIKE_COMMENT_DISCUSSION,
        NOTIFICATION_TYPE_NEW_LIKE_TIMELINE,
        NOTIFICATION_TYPE_CREATE_NEW_SKRIPSI,
        NOTIFICATION_TYPE_CREATE_NEW_BIMBINGAN,
        NOTIFICATION_TYPE_APPROVE_BIMBINGAN,
        NOTIFICATION_TYPE_INVITE_SKRIPSI,
        NOTIFICATION_TYPE_PENGAJUAN_SKRIPSI,
        NOTIFICATION_TYPE_PENGAJUAN_SIDANG
    } from '@/store/modules/auth'

    export default {
        props: {
            data: {
                type: Object,
                default: () => {}
            }
        },
        data () {
            return {
                NOTIFICATION_TYPE_GROUP_JOIN_REQUEST,
                NOTIFICATION_TYPE_GROUP_JOIN_REQUEST_APPROVED,
                NOTIFICATION_TYPE_GROUP_JOIN_REQUEST_REJECTED,
                NOTIFICATION_TYPE_GROUP_MEMBER_JOINED,
                NOTIFICATION_TYPE_GROUP_MEMBER_LEAVE,
                NOTIFICATION_TYPE_GROUP_MEMBER_ADDED_AS_ADMIN,
                NOTIFICATION_TYPE_GROUP_MEMBER_REMOVED_FROM_ADMIN,
                NOTIFICATION_TYPE_GROUP_MEMBER_INVITATION_RECEIVED,
                NOTIFICATION_TYPE_GROUP_DISCUSSION_CREATED,
                NOTIFICATION_TYPE_GROUP_DISCUSSION_NEW_COMMENT,
                NOTIFICATION_TYPE_GROUP_TIMELINE_CREATED,
                NOTIFICATION_TYPE_GROUP_TIMELINE_NEW_COMMENT,
                NOTIFICATION_TYPE_NEW_LIKE_COMMENT_TIMELINE,
                NOTIFICATION_TYPE_NEW_LIKE_COMMENT_DISCUSSION,
                NOTIFICATION_TYPE_NEW_LIKE_TIMELINE,
                NOTIFICATION_TYPE_CREATE_NEW_SKRIPSI,
                NOTIFICATION_TYPE_CREATE_NEW_BIMBINGAN,
                NOTIFICATION_TYPE_APPROVE_BIMBINGAN,
                NOTIFICATION_TYPE_INVITE_SKRIPSI,
                NOTIFICATION_TYPE_PENGAJUAN_SKRIPSI,
                NOTIFICATION_TYPE_PENGAJUAN_SIDANG
            }
        },
        computed: {
            notificaitonRoute () {
                switch (this.data.type) {
                    case NOTIFICATION_TYPE_GROUP_JOIN_REQUEST: return { name: 'GroupMember', params: { code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_JOIN_REQUEST_APPROVED: return { name: 'GroupMember', params: { code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_JOIN_REQUEST_REJECTED: return { name: 'GroupDetail', params: { code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_MEMBER_JOINED: return { name: 'GroupMember', params: { code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_MEMBER_LEAVE: return { name: 'GroupMember', params: { code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_MEMBER_ADDED_AS_ADMIN: return { name: 'GroupMember', params: { code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_MEMBER_REMOVED_FROM_ADMIN: return { name: 'GroupDetail', params: { code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_MEMBER_INVITATION_RECEIVED: return { name: 'GroupMember', params: { code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_DISCUSSION_CREATED: return { name: 'GroupDetailDiscussion', params: { slug: this.data.args ? this.data.args.discussion_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_DISCUSSION_NEW_COMMENT: return { name: 'GroupDetailDiscussion', params: { slug: this.data.args ? this.data.args.discussion_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_TIMELINE_CREATED: return { name: 'GroupDetailTimeline', params: { slug: this.data.args ? this.data.args.timeline_id : '', code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_GROUP_TIMELINE_NEW_COMMENT: return { name: 'GroupDetailTimeline', params: { slug: this.data.args ? this.data.args.timeline_id : '', code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_NEW_LIKE_COMMENT_TIMELINE: return { name: 'GroupDetailTimeline', params: { slug: this.data.args ? this.data.args.timeline_id : '', code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_NEW_LIKE_COMMENT_DISCUSSION: return { name: 'GroupDetailDiscussion', params: { slug: this.data.args ? this.data.args.discussion_slug : '' } }
                    case NOTIFICATION_TYPE_NEW_LIKE_TIMELINE: return { name: 'GroupDetailTimeline', params: { slug: this.data.args ? this.data.args.timeline_id : '', code: this.data.args ? this.data.args.group_slug : '' } }
                    case NOTIFICATION_TYPE_CREATE_NEW_SKRIPSI: return { name: 'MahasiswaBimbingan' }
                    case NOTIFICATION_TYPE_CREATE_NEW_BIMBINGAN: return { name: 'DetailMahasiswaBimbingan', params: { id: this.data.args ? this.data.args.skripsi_id : '' } }
                    case NOTIFICATION_TYPE_APPROVE_BIMBINGAN: return { name: 'DetailJadwalBimbingan', params: { id: this.data.args ? this.data.args.id : '' } }
                    case NOTIFICATION_TYPE_INVITE_SKRIPSI: return { name: 'InformasiSkripsi' }
                    case NOTIFICATION_TYPE_PENGAJUAN_SKRIPSI: return { name: 'AdminPengajuanSkripsi' }
                    case NOTIFICATION_TYPE_PENGAJUAN_SIDANG: return { name: 'PengajuanSidangSkripsi' }
                    default: return null
                }
            }
        }
    }
</script>
