import { buildFormData, multipartHeader } from '@/axios'
import axios from 'axios'
import { toFormData } from '@/mixins'
const API_PATH = 'group'

export default {
    namespaced: true,
    state: {
        loading: false,
        data: [],
        selected: {
            loading: false,
            data: {},
            contentFilter: {
                search: '',
                order: ''
            }
        },
        files: {
            loading: false,
            items: []
        }
    },
    mutations: {
        SET_DATA (state, val) {
            state.data = val
        },
        SET_LOADING (state, val) {
            state.loading = val
        },
        SET_FILE_LOADING (state, val) {
            state.files.loading = val
        },
        SET_FILE_DATA (state, val) {
            state.files.items = val
        },
        APPEND_FILE_DATA (state, val) {
            state.files.items = [...state.files.items, ...val]
        },
        PREPEND_FILE_DATA (state, val) {
            state.files.items = [...val, ...state.files.items]
        },
        SET_SELECTED_DATA (state, val) {
            state.selected.data = val
        },
        SET_SELECTED_LOADING (state, val) {
            state.selected.loading = val
        },
        SET_CONTENT_FILTER_SEARCH (state, val) {
            state.selected.contentFilter.search = val
        },
        SET_CONTENT_FILTER_ORDER (state, val) {
            state.selected.contentFilter.order = val
        }
    },
    actions: {
        show ({ commit }, slug) {
            commit('SET_SELECTED_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.post('group/detail', { slug })
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_SELECTED_DATA', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_SELECTED_LOADING', false))
            })
        },
        search ({ commit }, { slug, params }) {
            return new Promise((resolve, reject) => {
                axios.get(`group/search/${slug}`, { params })
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        update ({ commit }, { slug, data }) {
            data._method = 'PUT'
            commit('SET_SELECTED_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/${slug}`, toFormData(data), {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_SELECTED_LOADING', false))
            })
        },
        delete ({ commit }, slug) {
            commit('SET_SELECTED_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/${slug}`, { _method: 'DELETE' })
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_SELECTED_DATA', { author: {} })
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_SELECTED_LOADING', false))
            })
        },
        getAttachments ({ commit }, params) {
            commit('SET_FILE_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`group/${params.slug}/attachments`, { params })
                    .then((res) => res.data)
                    .then((data) => {
                        if (params.append) {
                            commit('APPEND_FILE_DATA', data.result.data)
                        } else {
                            commit('SET_FILE_DATA', data.result.data)
                        }
                        resolve(data.result)
                    })
                    .catch(reject)
                    .finally(() => {
                        commit('SET_FILE_LOADING', false)
                    })
            })
        },
        addFile ({ commit }, { slug, attachments }) {
            return new Promise((resolve, reject) => {
                axios.post(`group/${slug}/attachments`, buildFormData({ attachments }), multipartHeader)
                    .then((res) => res.data)
                    .then((res) => {
                        commit('PREPEND_FILE_DATA', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        changeBanner ({ commit }, { slug, attachments }) {
            return new Promise((resolve, reject) => {
                axios.post(`group/${slug}/change_banner`, buildFormData({ attachments }), multipartHeader)
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        deleteFile ({ commit, dispatch }, { slug, id }) {
            commit('SET_FILE_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.post(`group/${slug}/attachments/${id}`, buildFormData({ _method: 'DELETE' }), multipartHeader)
                    .then((res) => res.data)
                    .then(async (res) => {
                        await dispatch('getAttachments', slug)
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        leave ({ commmit }, id) {
            return new Promise((resolve, reject) => {
                axios.get(`group/leave/${id}`)
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then(resolve)
                    .catch(reject)
            })
        },
        approve ({ commit }, { groupId, userId }) {
            return new Promise((resolve, reject) => {
                axios.post('group/approve', { group_id: groupId, user_id: userId })
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        setAdmin ({ commit }, { groupId, userId }) {
            return new Promise((resolve, reject) => {
                axios.post('group/set-admin', { group_id: groupId, user_id: userId })
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        removeAdmin ({ commit }, { groupId, userId }) {
            return new Promise((resolve, reject) => {
                axios.post('group/remove-admin', { group_id: groupId, user_id: userId })
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        getInviteMemberList ({ commit }, { slug, search }) {
            return new Promise((resolve, reject) => {
                axios.get(`group/invite-member/${slug}`, { params: { search } })
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        inviteMember ({ commit }, { slug, members }) {
            return new Promise((resolve, reject) => {
                axios.post(`group/invite-member/${slug}`, { members })
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        getAddResourceList ({ commit }, { slug, search }) {
            return new Promise((resolve, reject) => {
                axios.get(`group/add-resource/${slug}`, { params: { search } })
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        AddResource ({ commit }, { slug, resources }) {
            return new Promise((resolve, reject) => {
                axios.post(`group/add-resource/${slug}`, { resources })
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        }
    }
}
