<template>
    <div id="wrapper" ref="wrapper">
        <nprogress-container></nprogress-container>
        <Header />
        <Sidebar />
        <div class="main_content" id="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    import Header from '@/components/Header.vue'
    import Sidebar from '@/components/Sidebar.vue'
    import NprogressContainer from 'vue-nprogress/src/NprogressContainer'

    export default {
        components: {
            Header,
            Sidebar,
            NprogressContainer
        }
    }
</script>
