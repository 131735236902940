import axios from 'axios'
import { buildFormData, multipartHeader } from '@/axios'
const API_PATH = 'skripsi'

export default {
    namespaced: true,
    state: {
        data: {},
        loading: false,
        mahasiswa: {
            filter: '',
            mahasiswaBimbingan: [],
            jadwalBimbinagan: []
        }
    },
    mutations: {
        SET_DATA (state, val) {
            state.data = val
        },
        SET_LOADING (state, val) {
            state.loading = val
        },
        SET_MAHASISWA_FILTER (state, val) {
            state.mahasiswa.filter = val
        },
        SET_MAHASISWA_BIMBINGAN (state, val) {
            state.mahasiswa.mahasiswaBimbingan = val
        },
        SET_MAHASISWA_JADWAL (state, val) {
            state.mahasiswa.jadwalBimbinagan = val
        }
    },
    actions: {
        fetch ({ commit }, params) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(API_PATH, { params })
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_DATA', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        fetchHistory ({ commit }, params) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/history`, { params })
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
            })
        },
        fetchMahasiswaBimbingan ({ commit }, params) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/mahasiswa-bimbingan`, { params })
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_MAHASISWA_BIMBINGAN', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        fetchJadwalBimbingan ({ commit }, params) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/jadwal-bimbingan`, { params })
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_MAHASISWA_JADWAL', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        fetchSidang ({ commit }, params) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/sidang`, params)
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        finishBimbingan ({ commit }, { id, data }) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/jadwal-bimbingan/${id}/finish`, data)
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        approveJadwalBimbingan ({ commit }, { id, data }) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/jadwal-bimbingan/${id}/approve`, data)
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        rescheduleJadwalBimbingan ({ commit }, { id, data }) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/jadwal-bimbingan/${id}/reschedule`, data)
                    .then((res) => res.data)
                    .then((res) => {
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        create ({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios.post(API_PATH, buildFormData(data), multipartHeader)
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        update ({ commit, dispatch }, { id, data }) {
            return new Promise((resolve, reject) => {
                axios.put(`${API_PATH}/${id}`, data)
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        createSidang ({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/sidang`, buildFormData(data), multipartHeader)
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        showSidang ({ commit, dispatch }, params) {
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/sidang`, { params })
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        updateSidang ({ commit, dispatch }, { id, data }) {
            return new Promise((resolve, reject) => {
                axios.put(`${API_PATH}/sidang/${id}`, data)
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        createBimbingan ({ commit, dispatch }, data) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/bimbingan`, buildFormData(data), multipartHeader)
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
            })
        },
        showBimbingan ({ commit, dispatch }, id) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/bimbingan/${id}`)
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        updateBimbingan ({ commit }, { id, data }) {
            commit('SET_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.put(`${API_PATH}/bimbingan/${id}`, data.file ? buildFormData({ ...data, _method: 'PUT' }) : { ...data, _method: 'PUT' }, data.file ? multipartHeader : null)
                    .then((res) => res.data)
                    .then((res) => res.result)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_LOADING', false))
            })
        },
        show ({ commit }, slug) {
            commit('SET_SELECTED_LOADING', true)
            return new Promise((resolve, reject) => {
                axios.get(`${API_PATH}/${slug}`)
                    .then((res) => res.data)
                    .then((res) => {
                        commit('SET_SELECTED_DATA', res.result)
                        resolve(res.result)
                    })
                    .catch(reject)
                    .finally(() => commit('SET_SELECTED_LOADING', false))
            })
        },
        addComment ({ commit }, { id, content, parentId, attachments }) {
            return new Promise((resolve, reject) => {
                axios.post(`${API_PATH}/bimbingan/${id}/comment`, buildFormData({ content, parent_id: parentId, attachments }), multipartHeader)
                    .then(res => res.data)
                    .then(res => res.result)
                    .then(resolve)
                    .catch(reject)
            })
        }
    }
}
